<template>
    <dn-input
        v-bind="$attrs"
        :validator="validator"
        :clearable="false"
        label=""
        v-on="$listeners"
        @blur="resetValidation"
    >
        <template v-if="validator" v-slot:status>
            <template v-if="validator.validated">
                <template v-if="validator.required">
                    <gw-icon v-if="validator.invalid" icon="alert" />
                    <gw-icon v-else-if="validator.valid" icon="check" />
                </template>
                <template v-else-if="charactedCount > 0">
                    <gw-icon v-if="validator.invalid" icon="alert" />
                    <gw-icon v-else-if="validator.valid" icon="check" />
                </template>
            </template>
        </template>
    </dn-input>
</template>

<script>
import { DnInput } from '@digitalnatives/form-input';
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        DnInput,
        GwIcon
    },
    props: {
        validator: {
            type: Object,
            default: null
        }
    },
    computed: {
        charactedCount() {
            const value = this.$attrs.value;

            if (value) {
                return value.length;
            } else {
                return 0;
            }
        }
    },
    methods: {
        resetValidation() {
            if (this.validator) {
                /* Reset validation when non-required fields are empty */
                if (this.charactedCount === 0 && !this.validator.required) {
                    this.validator.reset();
                }
            }
        }
    }
};
</script>

<style src="./input.less" lang="less"></style>
