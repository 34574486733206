<template>
    <multiselect
        v-model="selectedCountry"
        class="gw-countryselect"
        :options="options"
        track-by="name"
        label="name"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template slot="placeholder">
            <template v-if="!!$slots.placeholder">
                <slot name="placeholder" />
            </template>
            <template v-else>
                {{ $t('checkout.placeholders.select-country') }}
            </template>
        </template>

        <template slot="singleLabel" slot-scope="props">
            {{ props.option.name }}
        </template>

        <div slot="option" slot-scope="props" class="gw-countryselect__option">
            <div class="gw-countryselect__flag">
                {{ getFlagEmoji(props.option.code) }}
            </div>
            <div class="gw-countryselect__name">
                {{ props.option.name }}
            </div>
            <gw-icon class="gw-countryselect__check" icon="check" />
        </div>

        <template slot="caret">
            <template v-if="validator && validator.validated">
                <gw-icon
                    v-if="validator.invalid"
                    class="gw-countryselect__status gw-countryselect__status--invalid"
                    icon="alert"
                />
                <gw-icon
                    v-else
                    class="gw-countryselect__status gw-countryselect__status--valid"
                    icon="check"
                />
            </template>
            <template v-else>
                <gw-icon
                    class="gw-countryselect__caret"
                    icon="chevron-down"
                />
            </template>
        </template>

        <gw-icon slot="after" aclass="gw-countryselect__check" icon="check" />
    </multiselect>
</template>

<script>
import Bugsnag from '@bugsnag/js';
import Multiselect from '~/patterns/atoms/multiselect/multiselect.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        Multiselect,
        GwIcon
    },
    props: {
        validator: {
            type: Object,
            default: undefined
        },
        country: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            options: [],
            selectedCountry: null
        };
    },
    watch: {
        selectedCountry(payload) {
            this.$emit('countryChanged', payload);
        },
        country(country) {
            if (!country && this.selectedCountry !== null) {
                this.selectedCountry = null;
            }
        }
    },
    mounted() {
        this.getCountries();
    },
    methods: {
        getFlagEmoji(countryCode) {
            const codePoints = countryCode
                .toUpperCase()
                .split('')
                .map(char => 127397 + char.charCodeAt());
            return String.fromCodePoint(...codePoints);
        },
        async getCountries() {
            const language = this.$config.site.locale;
            try {
                await this.$axios.get(this.$config.apiUrl + `/api/countries?locale=${language}`)
                    .then((response) => {
                        this.options = response.data;
                    });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);

                Bugsnag.notify(error);
            }
        }
    }
};
</script>

<style src="./countryselect.less" lang="less"></style>
